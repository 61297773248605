import * as React from "react";
import {
    ActionButton,
    Callout, DatePicker, DayOfWeek,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Stack,
    TextField
} from "@fluentui/react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {FontIcon} from "@fluentui/react/lib/Icon";
import MoneyField from "../../../../MoneyField";
import {datePickerStringsRu} from "../../../../data/datePickerStringsRu";
import addFinanceWorkerMethod from "../../../../../api/addFinanceWorkerMethod";
import {useState} from "react";

export default function SpendingTable(
    {
        project,
        changeWorker,
        getCurrentParticipants,
        otherInput,
        setOtherInput,
        changeFinance,
        calculatePlanSpending,
        canFreeChangeSpendingTablePlan,
        canMakeNewSpendingTable,
        calculateFactSpending,
        canChangeFinanceFact,
        removeWorker,
        formatDate,
        isProject,
        spendingTableInfoBtn,
        reloadData,
        isLocked,
        toggleLock,
        showWorkerPaidAt = false
    }
) {
    const [isChangedAtVisible, setIsChangedAtVisible] = useState({});
    const [spendingTableId, setSpendingTableId] = useState('current');

    const formatDateTime = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.shortMonths[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = `${date.getHours()}`.padStart(2, "0");
        const minutes = `${date.getMinutes()}`.padStart(2, "0");

        return `${day} ${month} ${year} в ${hours}:${minutes}`;
    }

    const spendingTableHistoryOptions = [];
    project.data.finance.spendingTableHistory.forEach((item) => {
        spendingTableHistoryOptions.unshift({ key: item.id, text: 'от ' + formatDate(new Date(item.date)) });
    });
    spendingTableHistoryOptions.unshift({ key: 'current', text: 'Текущая версия' });

    const columns = [
        {
            key: 'work',
            name: 'Вид работ',
            fieldName: 'work',
            minWidth: 270,
            maxWidth: 270,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => <div>
                <TextField style={{paddingLeft: 12}} type="text" value={worker.work} onChange={(e) => changeWorker(worker.id, 'work', e.target.value)} />
            </div>,
        },
        {
            key: 'username',
            name: 'Исполнитель',
            fieldName: 'username',
            minWidth: 235,
            maxWidth: 235,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (worker) => {
                return <div style={{display: 'flex', gap: 10, alignItems: 'center', backgroundColor: '#f3f2f1'}}>
                    {worker.userId
                        ? <>
                            <Dropdown
                                style={{width: 245}}
                                placeholder="Выберите исполнителя"
                                options={getCurrentParticipants('WORKER')}
                                selectedKey={worker.userId}
                                onChange={(e, option) => changeWorker(worker.id, 'userId', option.key)}
                            />
                            <FontIcon id={'other-inp-btn-' + worker.id} aria-label="Edit" iconName="InsertTextBox" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle', fontSize: 16, width: 30}} onClick={() => setOtherInput({...otherInput, [worker.id]: true})} />
                            {otherInput[worker.id] && (
                                <Callout
                                    style={{width: 260}}
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${'other-inp-btn-' + worker.id}`}
                                    onDismiss={() => setOtherInput({...otherInput, [worker.id]: false})}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <TextField
                                            type="text"
                                            value={worker.username}
                                            onChange={(e) => changeWorker(worker.id, 'username', e.target.value)}
                                        />
                                    </div>
                                </Callout>
                            )}
                        </>
                        : <>
                            <TextField
                                style={{paddingLeft: 8, width: 245}}
                                // styles={{root: {width: 164}}}
                                type="text"
                                value={worker.username}
                                onChange={(e) => changeWorker(worker.id, 'username', e.target.value)}
                                id={'worker-inp-' + worker.id}
                            />
                            <FontIcon id={'other-inp-btn-' + worker.id} aria-label="BulletedList" iconName="PeopleAdd" style={{cursor: 'pointer', verticalAlign: '-webkit-baseline-middle', fontSize: 16, width: 30}} onClick={() => setOtherInput({...otherInput, [worker.id]: true})} />
                            {otherInput[worker.id] && (
                                <Callout
                                    styles={{root: {width: 260}}}
                                    role="dialog"
                                    gapSpace={0}
                                    target={`#${'other-inp-btn-' + worker.id}`}
                                    onDismiss={() => setOtherInput({...otherInput, [worker.id]: false})}
                                    setInitialFocus
                                >
                                    <div style={{padding: 20}}>
                                        <Dropdown
                                            placeholder="Выберите исполнителя"
                                            options={getCurrentParticipants('WORKER')}
                                            selectedKey={worker.userId}
                                            onChange={(e, option) => changeWorker(worker.id, 'userId', option.key)}
                                        />
                                    </div>
                                </Callout>
                            )}
                        </>
                    }
                </div>;
            },
        },
        {
            key: 'planPrice',
            name: 'Расходы (план)',
            fieldName: 'planPrice',
            minWidth: 250,
            maxWidth: 250,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => {
                return <div>
                    <Stack horizontal>
                        <div style={{width: '100%'}}>
                            <MoneyField value={worker.planPrice ?? null} onChange={async (e) => {
                                await changeWorker(worker.id, 'planPrice', e.target.value);
                                await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                            }} disabled={(!canFreeChangeSpendingTablePlan && isLocked) || !canMakeNewSpendingTable} />
                        </div>
                        <Dropdown
                            style={{width: 110}}
                            disabled={(!canFreeChangeSpendingTablePlan && isLocked) || !canMakeNewSpendingTable}
                            options={[
                                { key: 'без налога', text: 'без налога' },
                                { key: 'с налогом', text: 'с налогом' },
                            ]}
                            selectedKey={worker.priceType ?? 'без налога'}
                            onChange={async (e, option) => {
                                if (worker.priceType === undefined && option.key === 'без налога') {
                                    await changeWorker(worker.id, 'priceType', option.key);
                                    return;
                                }

                                if (option.key === 'с налогом' && worker.planPrice) {
                                    await changeWorker(worker.id, 'priceType', option.key);
                                    await changeWorker(worker.id, 'planPrice', Math.round(worker.planPrice / 0.94));
                                    await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                                    return;
                                }

                                if (option.key === 'без налога' && worker.planPrice) {
                                    await changeWorker(worker.id, 'priceType', option.key);
                                    await changeWorker(worker.id, 'planPrice', Math.round(worker.planPrice * 0.94));
                                    await changeFinance('estimatedSummarySpending', calculatePlanSpending(project.data.finance.workers));
                                    return;
                                }
                            }}
                        />
                    </Stack>
                </div>;
            },
        },
        {
            key: 'grossPrice',
            name: 'Расходы (факт)',
            fieldName: 'grossPrice',
            minWidth: 230,
            maxWidth: 230,
            isRowHeader: true,
            data: 'string',
            onRender: (worker) => <div>
                <MoneyField value={worker.grossPrice} onChange={async (e) => {
                    await changeWorker(worker.id, 'grossPrice', e.target.value);
                    await changeFinance('summarySpending', calculateFactSpending(project.data.finance.workers));
                }} disabled={!canChangeFinanceFact} />
            </div>,
        },
        showWorkerPaidAt
            ? {
                name: 'Дата оплаты',
                isRowHeader: true,
                minWidth: 90,
                maxWidth: 90,
                data: 'string',
                onRender: (worker) => <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers={false}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Выберите дату"
                    ariaLabel="Выберите дату"
                    strings={datePickerStringsRu}
                    formatDate={formatDate}
                    value={worker.paidAt ? new Date(worker.paidAt) : null}
                    onSelectDate={(date) => changeWorker(worker.id, 'paidAt', date.toDateString())}
                    disabled={!canChangeFinanceFact}
                />
            }
            : {
                name: '',
                isRowHeader: true,
                minWidth: 90,
                maxWidth: 90,
                data: 'string',
                onRender: (worker) => {
                    // if (!canChangeFinanceFact && !isLocked) {
                    //     return <></>;
                    // }

                    const options = [{ key: 'info', text: 'История' }];

                    if (canFreeChangeSpendingTablePlan || !isLocked) {
                        options.push({ key: 'remove', text: 'Удалить' });
                    }

                    return <>
                        <Dropdown
                            id={'changedAtButton' + worker.id}
                            style={{width: '100%'}}
                            placeholder="Действие"
                            selectedKey={null}
                            options={options}
                            onChange={async (e, option) => {
                                if (option.key === 'remove' && (canFreeChangeSpendingTablePlan || !isLocked)) {
                                    await removeWorker(worker.id);
                                    return;
                                }

                                if (option.key === 'info') {
                                    setIsChangedAtVisible({...isChangedAtVisible, [worker.id]: true});
                                }
                            }}
                        />
                        {isChangedAtVisible[worker.id] && (
                            <Callout
                                role="dialog"
                                gapSpace={0}
                                target={`#${'changedAtButton' + worker.id}`}
                                onDismiss={() => setIsChangedAtVisible({...isChangedAtVisible, [worker.id]: false})}
                                setInitialFocus
                            >
                                <div style={{padding: 20, paddingBottom: 30, width: 200}}>
                                    <div style={{fontSize: 12, fontWeight: 600}}>Расходы план</div>
                                    {worker.planPriceCreatedAt ? <div style={{fontSize: 12, marginTop: 3, color: 'gray'}}>Внесено: {formatDateTime(new Date(worker.planPriceCreatedAt))}</div> : <></>}
                                    {worker.planPriceUpdatedAt ? <div style={{fontSize: 12, marginTop: 3, color: 'gray'}}>Изменено: {formatDateTime(new Date(worker.planPriceUpdatedAt))}</div> : <></>}
                                    <div style={{marginTop: 10, fontSize: 12, fontWeight: 600}}>Рассходы факт</div>
                                    {worker.grossPriceUpdatedAt ? <div style={{fontSize: 12, marginTop: 3, color: 'gray'}}>Изменено: {formatDateTime(new Date(worker.grossPriceUpdatedAt))}</div> : <></>}
                                </div>
                            </Callout>
                        )}
                    </>;
                }
        },
    ];

    // await makeNewSpendingTableMethod(project.data.id); reloadData();

    return <div style={{marginTop: 20}}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
            <div className="ms-fontWeight-semibold">Раскладка</div>
            <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                {canMakeNewSpendingTable && spendingTableId === 'current' && <ActionButton style={{height: 32}} iconProps={{ iconName: isLocked ? 'Lock' : 'Unlock' }} allowDisabledFocus onClick={toggleLock}>
                    {isLocked ? 'Нажмите на замок, чтобы внести изменения.' : 'Нажмите на замок, чтобы запретить изменения.'}
                </ActionButton>}
                {project.data.finance.spendingTableHistory.length > 1 &&
                <Dropdown
                    style={{width: 172}}
                    placeholder="Выберите"
                    options={spendingTableHistoryOptions}
                    selectedKey={spendingTableId}
                    onChange={(e, option) => setSpendingTableId(option.key)}
                />}
            </div>
        </div>
        {project.data.finance.workers.length > 0
            ? <DetailsList
                styles={{ root: { paddingTop: 0 } }}
                items={spendingTableId === 'current' ? project.data.finance.workers : project.data.finance.spendingTableHistory.filter((item) => item.id === spendingTableId)[0].workers}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item, index) => item.id}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                className="editableTable noMarginTop"
            />
            : <></>
        }
        {!isLocked && <div style={{marginTop: 10}}>
            <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={async () => {await addFinanceWorkerMethod(project.data.id); reloadData();}}>
                Добавить пункт
            </ActionButton>
        </div>}
    </div>;
}
