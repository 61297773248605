import {shortMonths} from "../../../data/shortMonths";
import * as React from "react";
import {getStatusPill} from "../../../Project/ViewProject/Data/statuses";

export const tableColumns = [
    {
        key: 'code',
        name: 'Номер',
        fieldName: 'code',
        maxWidth: 80,
        minWidth: 80,
        isRowHeader: true,
        data: 'string',
    },
    {
        key: 'startedAt',
        name: 'Дата оплаты',
        fieldName: 'startedAt',
        maxWidth: 20,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.startedAt === null) {
                return <div>не указано</div>;
            }

            const date = new Date(item.startedAt);

            return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
        }
    },
    {
        key: 'name',
        name: 'Название',
        fieldName: 'name',
        maxWidth: 350,
        minWidth: 200,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.name === null) {
                return <div>не указано</div>;
            }

            return <div>{item.name}</div>;
        }
    },
    {
        key: 'customer',
        name: 'Заказчик',
        maxWidth: 350,
        minWidth: 200,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => {
            if (item.company !== null && item.company.name !== null) {
                return <div>{item.company.name}</div>;
            }

            if (item.contact.name !== null) {
                return <div>{(item.contact.lastName ?? '') + ' ' + (item.contact.firstName ?? item.contact.name) + ' ' + (item.contact.middleName ?? '')}</div>;
            }

            return <div></div>;
        }
    },
    {
        key: 'status',
        name: 'Статус',
        fieldName: 'status',
        maxWidth: 90,
        isRowHeader: true,
        data: 'string',
        onRender: (item) => getStatusPill(item.status),
    },
    {
        key: 'manager',
        name: 'Менеджер',
        fieldName: 'manager',
        isRowHeader: true,
        data: 'string',
        minWidth: 120,
        isPadded: true,
        onRender: (item) => {
            if (!item.manager) {
                return <div></div>;
            }

            return <div>{item.manager}</div>;
        }
    },
    {
        key: 'summaryPrice',
        name: 'Доход, руб.',
        fieldName: 'summaryPrice',
        isRowHeader: true,
        data: 'string',
        minWidth: 100,
        isPadded: true,
        onRender: (item) => {
            if (!item.summaryPrice) {
                return <div></div>;
            }

            return <div>{new Intl.NumberFormat().format(item.summaryPrice)} руб.</div>;
        }
    },
    {
        key: 'paid',
        name: 'Нам оплатили, руб.',
        fieldName: 'paid',
        isRowHeader: true,
        data: 'string',
        minWidth: 120,
        isPadded: true,
        onRender: (item) => {
            return <div>{new Intl.NumberFormat().format(item.paid)} руб.</div>;
        }
    },
    {
        key: 'left',
        name: 'Нам должны, руб.',
        isRowHeader: true,
        data: 'string',
        minWidth: 120,
        isPadded: true,
        onRender: (item) => {
            return <div>{new Intl.NumberFormat().format(item.summaryPrice - item.paid)} руб.</div>;
        }
    },
    {
        key: 'estimatedSummarySpending',
        name: 'Расход план, руб.',
        fieldName: 'estimatedSummarySpending',
        isRowHeader: true,
        data: 'string',
        minWidth: 100,
        isPadded: true,
        onRender: (item) => {
            if (!item.estimatedSummarySpending) {
                return <div></div>;
            }

            return <div>{new Intl.NumberFormat().format(item.estimatedSummarySpending)} руб.</div>;
        }
    },
    {
        key: 'summarySpending',
        name: 'Расход факт, руб.',
        fieldName: 'summarySpending',
        isRowHeader: true,
        data: 'string',
        minWidth: 100,
        isPadded: true,
        onRender: (item) => {
            if (!item.summarySpending) {
                return <div></div>;
            }

            return <div>{new Intl.NumberFormat().format(item.summarySpending)} руб.</div>;
        }
    },
    {
        key: 'estimatedSummaryMargin',
        name: 'Маржа план, руб.',
        fieldName: 'estimatedSummaryMargin',
        isRowHeader: true,
        data: 'string',
        minWidth: 100,
        isPadded: true,
        onRender: (item) => {
            if (!item.estimatedSummaryMargin) {
                return <div></div>;
            }

            return <div>{new Intl.NumberFormat().format(item.estimatedSummaryMargin)} руб.</div>;
        }
    },
    {
        key: 'estimatedMarginPercent',
        name: 'Маржа план, %',
        fieldName: 'estimatedMarginPercent',
        isRowHeader: true,
        data: 'string',
        minWidth: 80,
        isPadded: true,
        onRender: (item) => {
            if (!item.estimatedMarginPercent) {
                return <div></div>;
            }

            return <div>{item.estimatedMarginPercent+'%'}</div>;
        }
    },
];
