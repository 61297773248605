import * as React from "react";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {useEffect, useMemo, useState} from "react";
import {datePickerStringsRu} from "../data/datePickerStringsRu";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {Callout, DatePicker, DayOfWeek, DefaultButton, Stack} from "@fluentui/react";
import {months} from "../data/months";
import {FontIcon} from "@fluentui/react/lib/Icon";

export default function DateRangePicker({label, onChange, defaultDateFrom = null, defaultDateTo = null, defaultSelectedButtonId = null, excludeButtons = []}) {
    const [isDateRangeVisible, { toggle: toggleIsDateRangeVisible }] = useBoolean(false);
    const dateRangeButtonId = useId('date-range-button');
    const [dateRange, setDateRange] = useState([null, null]);
    const [selectedButtonId, selectButton] = useState(defaultDateFrom || defaultDateTo ? null : (defaultSelectedButtonId ?? 'ALL'));
    const [placeholder, setPlaceholder] = useState('Всё время');
    const [isDefaultDatesSet, setIsDefaultDatesSet] = useState(false);

    const fastSelectButtons = useMemo(
        () => {
            const currentYear = (new Date()).getFullYear();
            const currentMonthNumber = (new Date()).getMonth();
            const currentMonth = months[currentMonthNumber];
            const previousMonth = months[currentMonthNumber === 0 ? 11 : currentMonthNumber - 1];

            const previousMonthBeginDate = new Date();
            previousMonthBeginDate.setDate(1);
            previousMonthBeginDate.setMonth((new Date()).getMonth() - 1);
            previousMonthBeginDate.setHours(0,0,0,0);

            const previousMonthEndDate = new Date(previousMonthBeginDate.getFullYear(), previousMonthBeginDate.getMonth() + 1, 0);
            previousMonthEndDate.setHours(23,59,59,999);

            const currentMonthBeginDate = new Date();
            currentMonthBeginDate.setDate(1);
            currentMonthBeginDate.setHours(0,0,0,0);

            const currentMonthEndDate = new Date(currentMonthBeginDate.getFullYear(), currentMonthBeginDate.getMonth() + 1, 0);
            currentMonthEndDate.setHours(23,59,59,999);

            const currentYearBeginDate = new Date(new Date().getFullYear(), 0, 1);
            currentYearBeginDate.setHours(0,0,0,0);

            const currentYearEndDate = new Date(new Date().getFullYear(), 11, 31);
            currentYearEndDate.setHours(23,59,59,999);

            const previousYearBeginDate = new Date(new Date().getFullYear() - 1, 0, 1);
            previousYearBeginDate.setHours(0,0,0,0);

            const previousYearEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
            previousYearEndDate.setHours(23,59,59,999);

            // const weekBeginDate = new Date();
            // weekBeginDate.setTime(weekBeginDate.getTime() - (6 * 24 * 60 * 60 * 1000));
            // weekBeginDate.setHours(0,0,0,0);
            //
            // const weekEndDate = new Date();
            // weekEndDate.setHours(23,59,59,999);

            const yesterdayBeginDate = new Date();
            yesterdayBeginDate.setTime(yesterdayBeginDate.getTime() - (24 * 60 * 60 * 1000));
            yesterdayBeginDate.setHours(0,0,0,0);

            const yesterdayEndDate = new Date();
            yesterdayEndDate.setTime(yesterdayEndDate.getTime() - (24 * 60 * 60 * 1000));
            yesterdayEndDate.setHours(23,59,59,999);

            return [
                { id: 'ALL', text: 'Всё время', dateFrom: null, dateTo: null },
                { id: 'YESTERDAY', text: 'Вчера', dateFrom: yesterdayBeginDate, dateTo: yesterdayEndDate },
                // { id: '7DAYS', text: '7 дней', dateFrom: weekBeginDate, dateTo: weekEndDate },
                { id: 'PREVIOUS_MONTH', text: previousMonth, dateFrom: previousMonthBeginDate, dateTo: previousMonthEndDate },
                { id: 'CURRENT_MONTH', text: currentMonth, dateFrom: currentMonthBeginDate, dateTo: currentMonthEndDate },
                { id: 'PREVIOUS_YEAR', text: (currentYear - 1) + ' год', dateFrom: previousYearBeginDate, dateTo: previousYearEndDate },
                { id: 'CURRENT_YEAR', text: currentYear + ' год', dateFrom: currentYearBeginDate, dateTo: currentYearEndDate },
            ].filter((item) => !excludeButtons.includes(item.id));
        },
        [excludeButtons]
    );

    defaultDateFrom = defaultDateFrom ? (new Date(defaultDateFrom)) : null;
    defaultDateTo = defaultDateTo ? (new Date(defaultDateTo)) : null;

    useEffect(() => {
        if (!isDefaultDatesSet) {
            if (defaultSelectedButtonId) {
                const selectedButton = fastSelectButtons.filter((button) => button.id === defaultSelectedButtonId)[0];
                selectButton(defaultSelectedButtonId);
                setPlaceholder(selectedButton?.text);
            } else if (defaultDateFrom || defaultDateTo) {
                setPlaceholder((defaultDateFrom ? defaultDateFrom.toLocaleDateString() : '*') + ' - ' + (defaultDateTo ? defaultDateTo.toLocaleDateString() : '*'));
                setIsDefaultDatesSet(true);
            }
        }
    }, [isDefaultDatesSet, defaultDateFrom, defaultDateTo, defaultSelectedButtonId, fastSelectButtons]);

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    }

    const changeDateRange = (dateFrom, dateTo) => {
        if (dateFrom === null && dateTo === null) {
            setDateRange([null, null]);
            selectButton('ALL');
            setPlaceholder('Всё время');
            onChange(null, null, 'ALL');
            return;
        }

        if (dateFrom !== null) {
            dateFrom.setHours(0,0,0,0);
        }

        if (dateTo !== null) {
            dateTo.setHours(23,59,59,999);
        }

        setDateRange([dateFrom, dateTo]);
        setPlaceholder((dateFrom !== null ? dateFrom.toLocaleDateString() : '*') + ' - ' + (dateTo !== null ? dateTo.toLocaleDateString() : '*'));
        selectButton(null);
        onChange(dateFrom?.getTime(), dateTo?.getTime(), null);
    };

    // dateRange[0] === null && dateRange[1] === null ? 'Всё время' : (dateRange[0] !== null ? dateRange[0].toLocaleDateString() : '__') + ' - ' + (dateRange[1] !== null ? dateRange[1].toLocaleDateString() : '__')

    return <div>
        <Dropdown
            placeholder={placeholder}
            label={label}
            options={[]}
            id={dateRangeButtonId}
            onClick={toggleIsDateRangeVisible}
            styles={{title: {color: 'black'}, dropdown: {width: 185}}}
            onRenderCaretDown={() => {
                return <FontIcon aria-label="Calendar" iconName="Calendar" />
            }}
        />
        {isDateRangeVisible && (
            <Callout
                role="dialog"
                gapSpace={0}
                target={`#${dateRangeButtonId}`}
                onDismiss={toggleIsDateRangeVisible}
                setInitialFocus
            >
                <div style={{padding: 20}}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <DatePicker
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={false}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Начало периода"
                            ariaLabel="Выберите дату"
                            // label="Начало периода"
                            strings={datePickerStringsRu}
                            formatDate={formatDate}
                            value={dateRange[0] ?? (!defaultSelectedButtonId ? defaultDateFrom : null)}
                            onSelectDate={(date) => {
                                if (dateRange[0]?.toLocaleDateString() === date?.toLocaleDateString()) {
                                    changeDateRange(null, dateRange[1]);
                                } else if (dateRange[1] !== null && (dateRange[0] - dateRange[1]) < 0) {
                                    changeDateRange(date, null);
                                } else {
                                    changeDateRange(date, dateRange[1]);
                                }
                            }}
                            styles={{ textField: {color: 'black'}, root: {width: 180}}}
                            // maxDate={new Date()}
                        />
                        <DatePicker
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={false}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Конец периода"
                            ariaLabel="Выберите дату"
                            // label="Конец периода"
                            strings={datePickerStringsRu}
                            formatDate={formatDate}
                            value={dateRange[1] ?? (!defaultSelectedButtonId ? defaultDateTo : null)}
                            onSelectDate={(date) => {
                                if (dateRange[1]?.toLocaleDateString() === date?.toLocaleDateString()) {
                                    changeDateRange(dateRange[0], null);
                                } else {
                                    changeDateRange(dateRange[0], date);
                                }
                            }}
                            styles={{ textField: {color: 'black'}, root: {width: 180}}}
                            minDate={dateRange[0]}
                        />
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal style={{marginTop: 10}}>
                        {fastSelectButtons.map((fastSelectButton) => {
                            return <DefaultButton
                                text={fastSelectButton.text}
                                disabled={selectedButtonId === fastSelectButton.id}
                                onClick={() => {
                                    setDateRange([null, null]);
                                    selectButton(fastSelectButton.id);
                                    setPlaceholder(fastSelectButton.text);
                                    onChange(fastSelectButton.dateFrom?.getTime(), fastSelectButton.dateTo?.getTime(), fastSelectButton.id);
                                }}
                            />
                        })}
                    </Stack>
                </div>
            </Callout>
        )}
    </div>
};
