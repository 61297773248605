import * as React from "react";

export const statuses = [
    { key: 'IN_PROGRESS', text: 'IN PROGRESS' },
    { key: 'HOLD', text: 'HOLD' },
    { key: 'DONE', text: 'DONE' },
    { key: 'CANCELED', text: 'CANCELED' },
];

export const filterStatuses = [
    { key: 'ALL', text: 'Все статусы' },
    { key: 'IN_PROGRESS', text: 'IN PROGRESS' },
    { key: 'HOLD', text: 'HOLD' },
    { key: 'DONE', text: 'DONE' },
    { key: 'CANCELED', text: 'CANCELED' },
];

export const getStatusPill = (status) => {
    let pillBackgroundColor = '#d3e5ef';
    let textColor = '#1d3a4c';
    let circleColor = '#5b97bd';
    let name = 'IN PROGRESS';

    if (status === 'HOLD') {
        pillBackgroundColor = '#faeece';
        textColor = '#857864';
        circleColor = '#bf923d';
        name = 'HOLD';
    } else if (status === 'DONE') {
        pillBackgroundColor = '#dbeddb';
        textColor = '#213b2d';
        circleColor = '#6c9b7d';
        name = 'DONE';
    } else if (status === 'CANCELED') {
        pillBackgroundColor = '#ffe2dd';
        textColor = '#611a18';
        circleColor = '#e16e64';
        name = 'CANCELED';
    }

    return <div style={{
        backgroundColor: pillBackgroundColor,
        color: textColor,
        padding: '2px 8px',
        borderRadius: 20,
        // fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        width: 'fit-content',
        fontSize: 12
    }}>
        <div style={{
            backgroundColor: circleColor,
            width: 7,
            height: 7,
            borderRadius: 50
        }}></div>
        {name}
    </div>
};
