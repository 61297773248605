import * as React from "react";

export const tableColumns = [
    {
        key: 'date',
        name: 'Дата',
        fieldName: 'date',
        minWidth: 80,
        maxWidth: 80,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
    },
    {
        key: 'source',
        name: 'Источник',
        fieldName: 'source',
        minWidth: 140,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: false,
    },
    {
        key: 'name',
        name: 'Имя',
        fieldName: 'name',
        minWidth: 120,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'phoneNumber',
        name: 'Телефон',
        fieldName: 'phoneNumber',
        minWidth: 130,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: false,
    },
    {
        key: 'email',
        name: 'Email',
        fieldName: 'email',
        minWidth: 170,
        maxWidth: 180,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'status',
        name: 'Статус',
        fieldName: 'status',
        minWidth: 80,
        maxWidth: 80,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item) => {
            let pillBackgroundColor = '#f1f0ef';
            let textColor = '#373532';
            let circleColor = '#91918e';

            if (item.status === 'HOLD') {
                pillBackgroundColor = '#faeece';
                textColor = '#857864';
                circleColor = '#bf923d';
            } else if (item.status === 'WIN' || item.status === 'PAID') {
                pillBackgroundColor = '#dbeddb';
                textColor = '#213b2d';
                circleColor = '#6c9b7d';
            } else if (item.status === 'LOSE' || item.status === 'SPAM') {
                pillBackgroundColor = '#ffe2dd';
                textColor = '#611a18';
                circleColor = '#e16e64';
            } else if (item.status === 'PENDING') {
                pillBackgroundColor = '#d3e5ef';
                textColor = '#1d3a4c';
                circleColor = '#5b97bd';
            }

            return <div style={{
                backgroundColor: pillBackgroundColor,
                color: textColor,
                padding: '2px 8px',
                borderRadius: 20,
                // fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                width: 'fit-content',
                fontSize: 12
            }}>
                <div style={{
                    backgroundColor: circleColor,
                    width: 7,
                    height: 7,
                    borderRadius: 50
                }}></div>
                {item.status}
            </div>
        }
    },
    {
        key: 'utmSource',
        name: 'Рекл. канал',
        fieldName: 'utmSource',
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'utmCampaign',
        name: 'Рекл. кампания',
        fieldName: 'utmCampaign',
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'utmTerm',
        name: 'UTM term',
        fieldName: 'utmTerm',
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'utmContent',
        name: 'UTM content',
        fieldName: 'utmContent',
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'lastComment',
        name: 'Комментарий',
        fieldName: 'lastComment',
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
    },
];
