import * as React from "react";
import {
    ActionButton,
    DatePicker,
    DayOfWeek,
    DetailsList, DetailsListLayoutMode,
    SelectionMode,
    TextField
} from "@fluentui/react";
import addPaymentStepMethod from "../../../../../api/addPaymentStepMethod";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import MoneyField from "../../../../MoneyField";
import {datePickerStringsRu} from "../../../../data/datePickerStringsRu";
import changePaymentStepMethod from "../../../../../api/changePaymentStepMethod";
import removePaymentStepMethod from "../../../../../api/removePaymentStepMethod";

export default function PaymentSteps({project, setProject, setNotification, reloadData, changeFinance, enableToChangePrepaymentDate, setEnableToChangePrepaymentDate}) {

    const changePaymentStep = async (id, key, value) => {
        const paymentSteps = project.data.finance.paymentSteps.map((payment) => {
            if (payment.id === id) {
                payment[key] = value;
            }
            return payment;
        });
        setProject({...project, data: {...project.data, finance: {...project.data.finance, paymentSteps: paymentSteps}}});
        await changePaymentStepMethod(project.data.id, id, key, value);
        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const paymentSteps = project.data.finance.paymentSteps.map((payment, i) => {
        return {
            entity: 'paymentStep',
            id: payment.id,
            title: payment.title,
            type: payment.type,
            sum: payment.sum,
            status: payment.status,
            paidAt: payment.paidAt,
            onlinePayment: payment.onlinePayment,
            onlinePaymentId: payment.onlinePayment.id,
        }
    });

    if (project.data.finance.postPayment.status !== 'NOT_NEED') {
        paymentSteps.unshift({
            entity: 'postPayment',
            id: 'postPayment',
        });
    }

    if (project.data.finance.prepayment) {
        paymentSteps.unshift({
            entity: 'prepayment',
            id: 'prepayment',
        });
    }

    // return project.data.finance.postPayment.status === 'NOT_NEED'
    //         ? <></>
    //         : <>
    //             <Separator vertical />
    //             <div>
    //                 {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.postPayment.title ?? 'Постоплата'}</div>*/}
    //                 <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
    //                     <ActionButton style={{position: 'absolute', top: -20}} iconProps={{ iconName: 'Delete' }} allowDisabledFocus onClick={() => changeFinance('postPaymentStatus', 'NOT_NEED')} />
    //                 </div>
    //                 <Stack tokens={{ childrenGap: 10 }}>
    //                     <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
    //                         {project.data.finance.postPayment.type === 'ONLINE' && project.data.finance.postPayment.status !== 'PAID'
    //                             && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.postPayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />
    //                         }
    //                     </Stack>
    //                 </Stack>
    //             </div>
    //         </>
    // };

    // return <Stack tokens={{ childrenGap: 10 }}>
    //     <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
    //
    //     </Stack>
    //     <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
    //     </Stack>
    //     <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
    //
    //     </Stack>
    //     <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
    //         {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status !== 'PAID'
    //             && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.prepayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />
    //         }
    //     </Stack>
    // </Stack>

    const paymentColumns = [
        {
            key: 'name',
            name: 'Назначение платежа',
            minWidth: 410,
            maxWidth: 410,
            isRowHeader: true,
            data: 'string',
            onRender: (payment) => {
                if (payment.entity === 'prepayment') {
                    return <div>
                        <TextField value={project.data.finance.prepayment.title ?? 'Предоплата'} disabled={project.data.finance.prepayment.type === 'ONLINE' || !project.data.finance.isSteppedPayment} style={{color: 'black', paddingLeft: 12}} onChange={(e) => changeFinance('prepaymentTitle', e.target.value)} />
                    </div>
                }

                if (payment.entity === 'postPayment') {
                    return <div>
                        <TextField value={project.data.finance.postPayment.title ?? 'Постоплата'} disabled={true} style={{color: 'black', paddingLeft: 12}} />
                    </div>;
                }

                return <div>
                    <TextField placeholder="Введите назначение" style={{paddingLeft: 12}} value={payment.title} onChange={(e) => changePaymentStep(payment.id, 'title', e.target.value)} />
                </div>;
            },
        },
        {
            key: 'type',
            name: 'Тип платежа',
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            data: 'string',
            onRender: (payment) => {
                if (payment.entity === 'prepayment') {
                    return <div>
                        <Dropdown
                            placeholder="Выберите"
                            options={[
                                { key: 'ONLINE', text: 'Онлайн' },
                                { key: 'CLASSIC', text: 'По документам' },
                            ]}
                            selectedKey={project.data.finance.prepayment.type}
                            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}
                            styles={{ title: {color: 'black'}}}
                            onChange={(e, option) => changeFinance('prepaymentType', option.key)}
                        />
                    </div>;
                }

                if (payment.entity === 'postPayment') {
                    return <div>
                        <Dropdown
                            placeholder="Выберите"
                            options={[
                                { key: 'ONLINE', text: 'Онлайн' },
                                { key: 'CLASSIC', text: 'По документам' },
                            ]}
                            selectedKey={project.data.finance.postPayment.type}
                            styles={{ textField: {color: 'black'}}}
                            onChange={(e, option) => changeFinance('postPaymentType', option.key)}
                        />
                    </div>;
                }

                return <div>
                    <Dropdown
                        placeholder="Выберите"
                        options={[
                            { key: 'ONLINE', text: 'Онлайн' },
                            { key: 'CLASSIC', text: 'По документам' },
                        ]}
                        selectedKey={payment.type}
                        styles={{ textField: {color: 'black'}}}
                        onChange={(e, option) => changePaymentStep(payment.id, 'type', option.key)}
                    />
                </div>
            },
        },
        {
            key: 'sum',
            name: 'Сумма',
            minWidth: 140,
            maxWidth: 140,
            isRowHeader: true,
            data: 'string',
            onRender: (payment) => {
                if (payment.entity === 'prepayment') {
                    return <div>
                        <MoneyField value={project.data.finance.prepayment.sum} disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'} onChange={(e) => changeFinance('prepaymentSum', e.target.value)} />
                    </div>;
                }

                if (payment.entity === 'postPayment') {
                    return <div>
                        <MoneyField value={project.data.finance.postPayment.sum} onChange={(e) => changeFinance('postPaymentSum', e.target.value)} />
                    </div>;
                }

                return <div>
                    <MoneyField value={payment.sum} onChange={(e) => changePaymentStep(payment.id, 'sum', e.target.value)} />
                </div>;
            },
        },
        {
            key: 'status',
            name: 'Статус',
            minWidth: 180,
            maxWidth: 180,
            isRowHeader: true,
            data: 'string',
            onRender: (payment) => {
                if (payment.entity === 'prepayment') {
                    return <div>
                        <Dropdown
                            placeholder="Выберите"
                            options={[
                                { key: 'NOT_NEED', text: 'Не нужна' },
                                { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },
                                { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },
                                { key: 'VERIFYING', text: 'Ожидаем подтверждения' },
                                { key: 'PAID', text: 'Оплачено' },
                            ]}
                            selectedKey={project.data.finance.prepayment.status}
                            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}
                            styles={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' ? { title: {color: 'black'}} : {}}
                            onChange={(e, option) => changeFinance('prepaymentStatus', option.key)}
                        />
                    </div>;
                }

                if (payment.entity === 'postPayment') {
                    return <div>
                        <Dropdown
                            placeholder="Выберите"
                            options={[
                                { key: 'NOT_NEED', text: 'Не нужна' },
                                { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },
                                { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },
                                { key: 'VERIFYING', text: 'Ожидаем подтверждения' },
                                { key: 'PAID', text: 'Оплачено' },
                            ]}
                            selectedKey={project.data.finance.postPayment.status}
                            onChange={(e, option) => changeFinance('postPaymentStatus', option.key)}
                        />
                    </div>;
                }

                return <div>
                    <Dropdown
                        placeholder="Выберите"
                        options={[
                            { key: 'NOT_NEED', text: 'Не нужна' },
                            { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },
                            { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },
                            { key: 'VERIFYING', text: 'Ожидаем подтверждения' },
                            { key: 'PAID', text: 'Оплачено' },
                        ]}
                        selectedKey={payment.status}
                        onChange={(e, option) => changePaymentStep(payment.id, 'status', option.key)}
                    />
                </div>;
            },
        },
        {
            key: 'date',
            name: 'Дата оплаты',
            minWidth: 140,
            maxWidth: 140,
            isRowHeader: true,
            data: 'string',
            onRender: (payment) => {
                if (payment.entity === 'prepayment') {
                    return project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' && !enableToChangePrepaymentDate
                        ? <div onClick={() => setEnableToChangePrepaymentDate(true)}><TextField value={formatDate(new Date(project.data.finance.prepayment.paidAt))} disabled={true} style={{color: 'black'}} /></div>
                        : <div><DatePicker
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={false}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Выберите дату"
                            ariaLabel="Выберите дату"
                            strings={datePickerStringsRu}
                            formatDate={formatDate}
                            value={project.data.finance.prepayment.paidAt !== null ? new Date(project.data.finance.prepayment.paidAt) : null}
                            onSelectDate={(date) => changeFinance('prepaymentPaidAt', date.toDateString())}
                        /></div>;
                }

                if (payment.entity === 'postPayment') {
                    return <div>
                        <DatePicker
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={false}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Выберите дату"
                            ariaLabel="Выберите дату"
                            strings={datePickerStringsRu}
                            formatDate={formatDate}
                            value={project.data.finance.postPayment.paidAt !== null ? new Date(project.data.finance.postPayment.paidAt) : null}
                            onSelectDate={(date) => changeFinance('postPaymentPaidAt', date.toDateString())}
                            disabled={project.data.finance.postPayment.status === 'NOT_NEED'}
                        />
                    </div>;
                }

                return <div>
                    <DatePicker
                        firstDayOfWeek={DayOfWeek.Monday}
                        showWeekNumbers={false}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Выберите дату"
                        ariaLabel="Выберите дату"
                        strings={datePickerStringsRu}
                        formatDate={formatDate}
                        value={payment.paidAt !== null ? new Date(payment.paidAt) : null}
                        onSelectDate={(date) => changePaymentStep(payment.id, 'paidAt',  date.toDateString())}
                        disabled={payment.status === 'NOT_NEED'}
                    />
                </div>;
            },
        },
        {
            name: '',
            isRowHeader: true,
            minWidth: 90,
            maxWidth: 90,
            data: 'string',
            onRender: (payment) => {
                const items = [];

                if (payment.entity !== 'prepayment') {
                    items.unshift({ key: 'remove', text: 'Удалить' });
                }

                if (payment.entity === 'paymentStep' && payment.type === 'ONLINE') {
                    items.unshift({ key: 'copy', text: 'Скопировать ссылку' });
                }

                if (payment.entity === 'prepayment' && project.data.finance.prepayment.type === 'ONLINE') {
                    items.unshift({ key: 'copy', text: 'Скопировать ссылку' });
                }

                if (payment.entity === 'postPayment' && project.data.finance.postPayment.type === 'ONLINE') {
                    items.unshift({ key: 'copy', text: 'Скопировать ссылку' });
                }

                return <>
                    <Dropdown
                        style={{width: '100%'}}
                        placeholder="Действие"
                        selectedKey={null}
                        options={items}
                        onChange={async (e, option) => {
                            if (option.key === 'remove') {
                                if (payment.entity === 'postPayment') {
                                    await changeFinance('postPaymentStatus', 'NOT_NEED');
                                } else {
                                    await removePaymentStepMethod(project.data.id, payment.id);
                                    reloadData();
                                }
                                return;
                            }

                            if (option.key === 'copy') {
                                if (payment.entity === 'paymentStep') {
                                    if (!payment.onlinePayment) {
                                        setNotification({type: 'error', text: 'Ссылка не найдена. Пересоздайте платёж'});
                                        return;
                                    }

                                    await navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + payment.onlinePayment.id);
                                } else if (payment.entity === 'prepayment') {
                                    if (!project.data.finance.prepayment.onlinePayment) {
                                        setNotification({type: 'error', text: 'Ссылка не найдена. Пересоздайте платёж'});
                                        return;
                                    }

                                    await navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.prepayment.onlinePayment.id)
                                } else {
                                    if (!project.data.finance.postPayment.onlinePayment) {
                                        setNotification({type: 'error', text: 'Ссылка не найдена. Пересоздайте платёж'});
                                        return;
                                    }

                                    await navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.postPayment.onlinePayment.id)
                                }

                                setNotification({type: 'success', text: 'Ссылка на оплату скопирована'});
                            }
                        }}
                    />
                </>;
            }
        }
    ];

    return <>
        <DetailsList
            styles={{ root: { paddingTop: 0 } }}
            items={paymentSteps}
            compact={false}
            columns={paymentColumns}
            selectionMode={SelectionMode.none}
            getKey={(item, index) => item.id}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            className="editableTable noMarginTop"
        />
        <ActionButton style={{marginTop: 10}} text="Добавить платёж" iconProps={{ iconName: 'Add' }} allowDisabledFocus onClick={async () => {await addPaymentStepMethod(project.data.id); reloadData();}} />
    </>;
};
