import * as React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import getWaitingDocsProjectCollectionMethod from "../../api/getWaitingDocsProjectCollectionMethod";
import {shortMonths} from "../data/shortMonths";
import {Breadcrumb, DetailsList, DetailsListLayoutMode, Pivot, PivotItem, SelectionMode} from "@fluentui/react";
import {FontSizes} from "@fluentui/theme";
import {datePickerStringsRu} from "../data/datePickerStringsRu";
import {getStatusPill} from "../Project/ViewProject/Data/statuses";

export default function MakeDocumentCollection() {
    const navigate = useNavigate();
    const [projectCollection, setProjectCollection] = useState({loaded: false, data: []});

    useEffect(() => {
        if (!projectCollection.loaded) {
            getWaitingDocsProjectCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setProjectCollection({loaded: true, data: response});
            });
        }
    });

    const columns = [
        {
            key: 'code',
            name: 'Номер',
            fieldName: 'code',
            maxWidth: 80,
            minWidth: 80,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: 'startedAt',
            name: 'Дата старта',
            fieldName: 'startedAt',
            maxWidth: 20,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.startedAt === null) {
                    return <div>не указано</div>;
                }

                const date = new Date(item.startedAt);

                if ((new Date()).getFullYear() === date.getFullYear()) {
                    return <div>{date.getDate()} {datePickerStringsRu.monthDeclinations[date.getMonth()]}</div>;
                }

                return <div>{date.getDate()} {shortMonths[date.getMonth()]} {date.getFullYear()}</div>;
            }
        },
        {
            key: 'name',
            name: 'Название',
            fieldName: 'name',
            maxWidth: 200,
            minWidth: 140,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.name === null) {
                    return <div>не указано</div>;
                }

                return <div>{item.name}</div>;
            }
        },
        {
            key: 'status',
            name: 'Статус',
            fieldName: 'status',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => getStatusPill(item.status),
        },
        {
            key: 'milestone',
            name: 'Этап',
            fieldName: 'milestone',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            onRender: (item) => {
                if (item.milestone === null) {
                    return <div>не указано</div>;
                }

                return <div>{item.milestone}</div>;
            }
        },
        {
            key: 'contact',
            name: 'Контакт',
            fieldName: 'contact',
            maxWidth: 160,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <div>{item.contact.name ?? item.contact.firstName}<br/>{item.contact.phoneNumber}<br/>{item.contact.email}</div>
            }
        },
        {
            key: 'service',
            name: 'Услуги',
            fieldName: 'service',
            maxWidth: 90,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item.services.length === 0) {
                    return <div>не выбраны</div>;
                }

                const services = item.services.map((service) => <div>{service.name}</div>);

                return <div>{services}</div>
            }
        },
        {
            key: 'summaryPrice',
            name: 'Стоимость',
            fieldName: 'summaryPrice',
            isRowHeader: true,
            data: 'string',
            minWidth: 120,
            isPadded: true,
            onRender: (item) => {
                if (!item.summaryPrice) {
                    return <div>не указана</div>;
                }

                return <div>{new Intl.NumberFormat().format(item.summaryPrice)} руб.</div>;
            }
        },
    ];

    const [breadcrumbs, setBreadcrumbs] = useState([
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Документы', key: 'waiting-docs-projects', href: '/waiting-docs-projects', isCurrentItem: true },
    ]);

    return (<>
        <Breadcrumb
            items={breadcrumbs}
            maxDisplayedItems={10}
            ariaLabel="Навигация"
            overflowAriaLabel="Показать больше"
            style={{marginBottom: 10}}
        />
        <div className="ms-fontWeight-semibold" style={{marginBottom: 20, color: '#999999'}}>Запросы на подготовку документов</div>
        {/*<Pivot aria-label="Меню" selectedKey="prepare" linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}}>*/}
        {/*    <PivotItem headerText="Запросы на подготовку документов" itemKey="prepare">*/}
                <DetailsList
                    items={projectCollection.data}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={(item, index) => item.key}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={(props, Row) => {
                        return <div style={{cursor: 'pointer'}} onClick={(e) => {
                            if (e.target.classList.contains('archive')) {
                                return;
                            }

                            navigate('/waiting-docs-projects/' + props.item.id);
                        }}>
                            <Row {...props} />
                        </div>;
                    }}
                />
        {/*    </PivotItem>*/}
        {/*</Pivot>*/}
    </>);
};
